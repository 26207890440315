import { Product } from '../types';

export const aiSolutions: Product[] = [
  {
    id: 'ai-tender',
    title: 'AI Tendering Manager',
    description: 'Streamline your tendering process with intelligent automation. Our AI-powered system analyzes requirements, suggests optimal pricing, and automates proposal generation.',
    icon: 'FileText',
    path: '/contact',
    category: 'ai'
  },
  {
    id: 'ai-crm',
    title: 'AI CRM Manager',
    description: 'Transform customer relationships with predictive analytics and intelligent automation. Get actionable insights, automate follow-ups, and optimize customer engagement.',
    icon: 'Users',
    path: '/contact',
    category: 'ai'
  },
  {
    id: 'ai-hr',
    title: 'AI HR Manager',
    description: 'Revolutionize HR processes with AI-driven talent management. Automate recruitment, optimize workforce planning, and enhance employee experience.',
    icon: 'UserPlus',
    path: '/contact',
    category: 'ai'
  },
  {
    id: 'ai-hospital',
    title: 'AI Hospital Appointment Manager',
    description: 'Optimize healthcare scheduling with intelligent appointment management. Reduce wait times, prevent no-shows, and improve patient satisfaction.',
    icon: 'Calendar',
    path: '/contact',
    category: 'ai'
  },
  {
    id: 'ai-chatbot',
    title: 'AI Chatbot Solutions',
    description: 'Enhance customer support with 24/7 intelligent conversational AI. Handle inquiries, automate responses, and provide personalized assistance.',
    icon: 'MessageSquare',
    path: '/contact',
    category: 'ai'
  },
  {
    id: 'ai-invoice',
    title: 'AI Invoice Manager',
    description: 'Automate invoice processing with intelligent document analysis. Extract data, validate information, and streamline payment workflows.',
    icon: 'Receipt',
    path: '/contact',
    category: 'ai'
  },
  {
    id: 'ai-auto-vision',
    title: 'AI Auto Vision',
    description: 'Advanced computer vision solutions for automated quality control, defect detection, and visual inspection in manufacturing and industrial processes.',
    icon: 'Eye',
    path: '/contact',
    category: 'ai'
  },
  {
    id: 'ai-travel',
    title: 'AI Travel Planner',
    description: 'Intelligent travel planning and optimization solution. Automate itinerary creation, optimize routes, and provide personalized recommendations based on preferences and real-time data.',
    icon: 'Plane',
    path: '/contact',
    category: 'ai'
  },
  {
    id: 'ai-tutor',
    title: 'AI Tutor',
    description: 'Personalized learning experience powered by AI. Adaptive curriculum, real-time feedback, and intelligent progress tracking to enhance educational outcomes.',
    icon: 'GraduationCap',
    path: '/contact',
    category: 'ai'
  },
  {
    id: 'ai-social-media',
    title: 'AI Social Media',
    description: 'Intelligent social media management platform. Automate content creation, optimize posting schedules, analyze engagement metrics, and generate data-driven insights.',
    icon: 'MessageSquare',
    path: '/contact',
    category: 'ai'
  },
  {
    id: 'ai-voice-assistant',
    title: 'AI Voice Assistant',
    description: 'Advanced voice-enabled AI assistant for seamless customer interactions. Natural language processing, multi-language support, and intelligent response generation.',
    icon: 'Mic',
    path: '/contact',
    category: 'ai'
  }
];