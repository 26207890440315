import { 
  FileText, Users, UserPlus, Calendar, MessageSquare, Receipt,
  Brain, Globe, Code, Network, Zap, LineChart, DollarSign,
  Shield, Expand, Laptop, Database, ShoppingCart, Utensils,
  Eye, TrendingUp, Cloud, Building2, Lightbulb, Wrench, Store, GraduationCap,
  Plane, Construction, Mic
} from 'lucide-react';

export const IconMap: { [key: string]: any } = {
  FileText,
  Users,
  UserPlus,
  Calendar,
  MessageSquare,
  Receipt,
  Brain,
  Globe,
  Code,
  Network,
  Zap,
  LineChart,
  DollarSign,
  Shield,
  Expand,
  Laptop,
  Database,
  ShoppingCart,
  Utensils,
  Eye,
  TrendingUp,
  Cloud,
  Building2,
  Lightbulb,
  Wrench,
  GraduationCap,
  Plane,
  Store,
  Construction,
  Mic
};