export const partners = [
  {
    name: 'Aljedad',
    logo: '/Aljedad-logo.svg',
    alt: 'Aljedad Logo'
  },
  {
    name: 'Bombaslo Tech',
    logo: '/Bombaslo-tech.svg',
    alt: 'Bombaslo Tech Logo'
  },
  
  {
    name: 'Green Shield',
    logo: '/green-shield.png',
    alt: 'Green Shield Logo'
  },
  
  {
    name: 'ASC Qatar',
    logo: '/ascqatar.png',
    alt: 'ASC Qatar Logo'
  },
  {
    name: 'Optimum Solutions',
    logo: '/OptimumSolutions.png',
    alt: 'Optimum Solutions Logo'
  },
  {
    name: 'SS',
    logo: '/AISS.webp',
    alt: 'SS Logo'
  }
];